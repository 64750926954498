import React, { useEffect, useState } from "react";
import ContactForm from "./ContactForm";
import Flag from "react-world-flags"

const LandingPage = () => {
  const [content, setContent] = useState(null);
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    fetch(`/assets/content-${language}.json`) 
      .then((response) => response.json())
      .then((data) => setContent(data));
  }, [language]); 

  if (!content) return <div className="text-center p-20 text-lg">Loading...</div>;

  const { companyName, tagline, sections } = content;

  return (
    <div className="font-sans">

      {/* Header */}
      <header className="bg-gradient-to-r from-blue-500 to-purple-600 text-white text-center py-12">
        <div className="flex justify-center">
          <img
            src="/logo192.png"
            alt="Menesoft Logo"
            className="h-16 w-16"
          />
        </div>
        <div className="flex items-center justify-center">
          <div>
            <h1 className="text-4xl font-bold">{companyName}</h1>
            <p className="text-lg mt-2">{tagline}</p>
          </div>
        </div>
        {/* Language Switcher */}
        <div className="flex justify-center">
            <button
            onClick={() => setLanguage("en")}
            >  <Flag code="US" className="h-5 w-5 rounded-full" />
            </button>
            <button
            onClick={() => setLanguage("pt")}
            >
            <Flag code="BR" className="h-5 w-5 rounded-full" />
            </button>
        </div>
      </header>

      {/* About Section */}
      <section
        id="about"
        className="py-12 px-6 bg-gray-100 text-gray-800 text-center"
      >
        <h2 className="text-3xl font-semibold">{sections.about.title}</h2>
        <p className="mt-4 max-w-2xl mx-auto">{sections.about.content}</p>
      </section>

      {/* Services Section */}
      <section id="services" className="py-12 px-6 bg-white text-gray-800">
        <h2 className="text-3xl font-semibold text-center">
          {sections.services.title}
        </h2>
        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {sections.services.items.map((service, index) => (
            <div
              key={index}
              className="border rounded-lg p-6 shadow-sm hover:shadow-md transition"
            >
              <div className="text-5xl mb-4">{service.icon}</div>
              <h3 className="text-xl font-semibold">{service.title}</h3>
              <p className="mt-2 text-sm">{service.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Call to Action Section */}
      <section
        id="cta"
        className="py-12 px-6 bg-gradient-to-r from-purple-600 to-blue-500 text-white text-center"
      >
        <h2 className="text-3xl font-bold">{sections.cta.title}</h2>
        <a
          href={sections.cta.link}
          className="mt-4 inline-block bg-white text-purple-600 font-semibold py-3 px-6 rounded-full shadow-lg hover:bg-gray-200 transition"
        >
          {sections.cta.button}
        </a>
      </section>

      {/* Contact Form Section */}
      <ContactForm language={language} />

      {/* Footer */}
      <footer className="py-6 bg-gray-800 text-center text-gray-300 text-sm">
        &copy; {new Date().getFullYear()} {companyName}. All rights reserved.
      </footer>
    </div>
  );
};

export default LandingPage;